"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.resourceMatches = function (pattern, res) {
  var separator = /[\/\.]/;
  pattern = pattern || '';

  if (pattern.indexOf('*') < 0) {
    pattern = pattern + '.*';
  }

  var parts = pattern.split(separator);
  var testParts = res.split(separator);
  var matches = true;

  for (var ii = 0; matches && ii < parts.length; ii++) {
    if (parts[ii] === '*') {
      continue;
    } else if (ii < testParts.length) {
      matches = parts[ii].toLowerCase() === testParts[ii].toLowerCase();
    } else {
      matches = false;
    }
  }

  return matches;
};

var OPERATION_ALIASES = {
  read: 'r',
  write: 'w'
};
var KNOWN_OPERATIONS = ['r', 'w'];

exports.checkRule = function (rules, operation, resource) {
  if (!rules) {
    return false;
  }

  operation = operation.toLowerCase();
  operation = OPERATION_ALIASES[operation] || operation;

  if (!KNOWN_OPERATIONS.includes(operation)) {
    throw new Error("Invalid rule operation: ".concat(operation));
  }

  var permission = false; // Everything is restricted by default

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = rules[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var rule = _step.value;
      var op = rule.op;

      if (!op || op.length < 2 || op.length > 4) {
        throw new Error("Invalid rule operation: ".concat(op));
      }

      if (!exports.resourceMatches(rule.res, resource)) {
        continue;
      }

      if (op.length === 4) {
        // `+r-w` form
        if (op[1] === operation) {
          permission = op[0] === '+';
        } else if (op[3] === operation) {
          permission = op[2] === '+';
        } else {
          permission = false;
        }
      } else if (op.length === 3) {
        // `+rw` form
        permission = op[0] === '+';
      } else if (op.length === 2) {
        // `+r` form
        if (op[1] === operation) {
          permission = op[0] === '+';
        }
      } // else leave the permission untouched

    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return permission;
};

exports.checkMultipleRoles = function (roles, operation, resource) {
  if (!roles) {
    return false;
  }

  for (var roleName in roles) {
    if (exports.checkRule(roles[roleName], operation, resource)) {
      return true;
    }
  }

  return false;
};